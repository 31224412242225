function getQueryParameters(str) {
  return (str || document.location.search).replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this;}.bind({}))[0];
}

$(function() {
  var $container, link, photoId;
  $container = jQuery('.gallery-photos-list').imagesLoaded(function() {
    return $('.gallery-photos-list').isotope({
      itemSelector: '.gallery-item',
      layoutMode: 'masonry'
    });
  });
  $(".fancybox").fancybox({
    beforeLoad: function() {
      return this.content = `<div class='video-wrap'>
  <video src='${this.href}' id='video_player' width='100%' height='auto' controls preload='auto' class='video-js'>
    <source src='${this.href}' type='video/webm' />
  </video>
</div>`;
    },
    beforeShow: function() {
      var downloadUrl, shareUrl, shareUrlEncoded;
      downloadUrl = jQuery(this.element).data('download-url');
      shareUrl = jQuery(this.element).data('share-url');
      shareUrlEncoded = encodeURIComponent(shareUrl);
      if (shareUrl) {
        return this.title = `<a href="${downloadUrl}" class="download-link" download><i class='fa fa-download'></i> Download</a>
<div class="share-links">
  <a href="https://twitter.com/share" class="twitter-share-button" data-count="none" data-url="${shareUrl}"></a>
  <iframe src="//www.facebook.com/plugins/like.php?href=${shareUrlEncoded}&amp;width=200&amp;layout=button&amp;action=like&amp;show_faces=true&amp;share=true&amp;height=80" scrolling="no" frameborder="0" style="border:none; overflow:hidden; height:20px;" allowTransparency="true"></iframe>
</div>`;
      }
    },
    afterShow: function() {
      twttr.widgets.load();
      if ($.fancybox.inner.find('.video-wrap')) {
        return new ResizeSensor($.fancybox.inner.find('.video-wrap'), $.fancybox.update);
      }
    },
    helpers: {
      title: {
        type: 'inside'
      }
    }
  });
  photoId = getQueryParameters()['photo_id'];
  if (photoId) {
    link = $('#photo-' + photoId);
    if (link) {
      return $(link).trigger('click');
    }
  }
});
